.form-group .coordinates {
  display: flex;
}

.form-group .coordinates input {
  width: 8vw;
  margin: 0 10px;
}

.form-group input[type="checkbox"] {
  width: 20px;
}

.form-group .structure-select {
  width: 20vw;
  /* display: none; */
}

.form-group textarea {
  width: 80%;
  height: 150px;
}

.btn {
  margin: 0 10px;
}
