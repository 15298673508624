.login {
  display: flex;
  justify-content: space-around;
}

.signin, .signup {
  background: #d9d9d9;
  box-shadow: 2px 2px 5px #333;
  width: 45vw;
}

.login-message {
  padding: 20px;
  font-size: 0.8rem;
}

.invalid-submission {
  display: none;
  padding: 0 20px;
  color: red;
}

.signin-link, .signup-link {
  text-decoration: none;
  color: #0671e0;
}

.login-label {
  text-align: center;
  font-size: 2.5rem;
  padding: 5px;
}
