.map {
  width: 100vw;
  height: 100vh;
}

.map-overlay {
  background-color: rgb(217, 217, 217, 0.6);
  width: 100%;
  height: 100%;
}

.map-destinations a {
  text-decoration: none;
  color: black;
}

.destination-icon .primary-tooltip {
  font-size: 1rem;
  visibility: hidden;
  min-width: 150px;
  max-height: 200px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opacity */
  opacity: 0;
  transition: 0.75s;
}

.destination-icon .secondary-tooltip {
  position: relative;
  left: -50%;
  padding: 5px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
}

.destination-icon:hover .primary-tooltip {
  visibility: visible;
  opacity: 1;
}

.destination-icon .icon {
  transition: 0.25s;
}

.destination-icon .icon:hover {
  transform: scale(1.1);
}

.map-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  right: 0;
  position: absolute;
  width: 30vw;
  height: 80vh;
  background-color: #d9d9d9;
  box-shadow: 2px 2px 5px #333;
}

.map-top .form-group {
  display: flex;
  justify-content: space-between;
}

.map-bottom {
  align-self: flex-end;
  margin: 20px;
  display: block;
}

.map-bottom div {
  padding: 5px;
}

.map-sidebar .form-group input {
  width: 80%;
}

.map-sidebar .form-group, .map-sidebar .select-group {
  padding: 10px 20px;
}

.map-sidebar .map-list {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 50vh;
}

.map-list li {
  margin-left: 20px;
  list-style: none;
}

.map-list li a {
  text-decoration: none;
  color: black;
}

.map-sidebar-collapsed {
  top: 0;
  right: 0;
  font-size: 1.5rem;
  position: absolute;
  padding: 10px 20px;
}
