@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap);


* {
  font-family: 'Inter', sans-serif;
  padding: 0;
  margin: 0;
}

.navbar {
  display: block;
  text-align: center;
  background: #d9d9d9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
}

.navbar h1 {
  padding-top: 10px;
  font-size: 3rem;
}

.btn {
  font-family: 'Inter', sans-serif;
  border-radius: 3px;
  border-width: 0;
  background-color: #0671e0;
  color: #fff;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px #333;
}

.btn:hover {
  background-color: #218dfc;
}

.btn:disabled {
  background-color: #616060;
  box-shadow: inset 2px 2px 5px #333;
}

.form-group, .select-group {
  padding: 20px;
  font-size: 1.5rem;
}

.form-group input, .select-group select, .form-group textarea {
  padding: 10px;
  border-style: none;
  border-radius: 3px;
  background-color: #eef5fc;
  width: 60%;
}

.form-group input::-webkit-input-placeholder, .select-group select::-webkit-input-placeholder {
  font-family: 'Inter', sans-serif;
}

.form-group input::placeholder, .select-group select::placeholder {
  font-family: 'Inter', sans-serif;
}

.select-group option {
  background-color: #eef5fc;
}

.icon {
  cursor: pointer;
}

.destination-navbar {
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}

.destination-overlay {
  position: absolute;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: inherit;
  background-color: rgb(217, 217, 217, 0.4);
}

.destination-navbar .destination-title {
  font-size: 1.5rem;
  display: flex;
  align-self: center;
}

.destination-navbar ._NGZSv {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-left: 10px;
}

.destination-navbar ._IYz6Z {
  display: flex;
}

.destination-navbar .btn {
  margin: 10px;
}

.destination {
  display: flex;
}

.destination-coordinates {
  flex: 1 1;
  background: #d9d9d9;
  margin: 20px;
  padding: 20px;
  height: 40vh;
  box-shadow: 2px 2px 5px #333;
  overflow: hidden;
  overflow-y: auto;
}

.destination-contains {
  flex: 2 1;
  background: #d9d9d9;
  margin: 20px;
  padding: 20px;
  height: 30vh;
  box-shadow: 2px 2px 5px #333;
  overflow: hidden;
  overflow-y: auto;
}

.destination-contains li {
  margin-left: 30px;
}

.destination-notes {
  flex: 1 1;
  background: #d9d9d9;
  margin: 20px;
  padding: 20px;
  height: 40vh;
  box-shadow: 2px 2px 5px #333;
}

.destination-notes .notes-area {
  cursor: pointer;
  height: 80% !important;
}

.destination-notes .notes-area:hover {
  background-color: #c4c4c4;
  transition: background-color 0.3s;
}

.destination-form input {
  padding: 10px;
  margin: 5px 0;
  border-style: none;
  border-radius: 3px;
  background-color: #eef5fc;
  width: 60%;
}

.destination-form input[type="number"] {
  width: 40%;
}

.destination-form button, .unstyled-button {
  all: unset;
  cursor: pointer;
}

.login {
  display: flex;
  justify-content: space-around;
}

.signin, .signup {
  background: #d9d9d9;
  box-shadow: 2px 2px 5px #333;
  width: 45vw;
}

.login-message {
  padding: 20px;
  font-size: 0.8rem;
}

.invalid-submission {
  display: none;
  padding: 0 20px;
  color: red;
}

.signin-link, .signup-link {
  text-decoration: none;
  color: #0671e0;
}

.login-label {
  text-align: center;
  font-size: 2.5rem;
  padding: 5px;
}

.navbar .btn {
  margin-top: 10px;
  margin-right: 10px;
  float: right;
}

.menu-content {
  display: flex;
}

.menu-content h1 {
  font-size: 2rem;
}

.select-world {
  flex: 2 1;
  padding: 20px;
  text-align: center;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.img-holder {
  max-width: 50vw;
  max-height: 50vh;
  margin: 10px 0;
  box-shadow: 2px 2px 5px #333;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.img-holder:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.change-world {
  flex: 1 1;
  padding: 20px;
}

.create-world, .add-world {
  padding: 10px;
}

.select-world .btn {
  margin: 10px;
  color: transparent;
  width: 135px;
}

.select-world .selector {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.select-world .selector .world-name {
  margin: 10px;
}

.select-world .btn::-webkit-file-upload-button {
  visibility: hidden;
}

.select-world .btn::before {
  content: 'Upload Custom Image';
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.select-world .world-name {
  margin: 5px 0;
  font-size: 1.5rem;
}

.select-world .holder, .change-world .holder {
  background: #d9d9d9;
  box-shadow: 2px 2px 5px #333;
  padding: 10px;
}

.add-world input::-webkit-outer-spin-button,
.add-world input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.map {
  width: 100vw;
  height: 100vh;
}

.map-overlay {
  background-color: rgb(217, 217, 217, 0.6);
  width: 100%;
  height: 100%;
}

.map-destinations a {
  text-decoration: none;
  color: black;
}

.destination-icon .primary-tooltip {
  font-size: 1rem;
  visibility: hidden;
  min-width: 150px;
  max-height: 200px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opacity */
  opacity: 0;
  transition: 0.75s;
}

.destination-icon .secondary-tooltip {
  position: relative;
  left: -50%;
  padding: 5px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
}

.destination-icon:hover .primary-tooltip {
  visibility: visible;
  opacity: 1;
}

.destination-icon .icon {
  transition: 0.25s;
}

.destination-icon .icon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.map-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  right: 0;
  position: absolute;
  width: 30vw;
  height: 80vh;
  background-color: #d9d9d9;
  box-shadow: 2px 2px 5px #333;
}

.map-top .form-group {
  display: flex;
  justify-content: space-between;
}

.map-bottom {
  align-self: flex-end;
  margin: 20px;
  display: block;
}

.map-bottom div {
  padding: 5px;
}

.map-sidebar .form-group input {
  width: 80%;
}

.map-sidebar .form-group, .map-sidebar .select-group {
  padding: 10px 20px;
}

.map-sidebar .map-list {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 50vh;
}

.map-list li {
  margin-left: 20px;
  list-style: none;
}

.map-list li a {
  text-decoration: none;
  color: black;
}

.map-sidebar-collapsed {
  top: 0;
  right: 0;
  font-size: 1.5rem;
  position: absolute;
  padding: 10px 20px;
}

.form-group .coordinates {
  display: flex;
}

.form-group .coordinates input {
  width: 8vw;
  margin: 0 10px;
}

.form-group input[type="checkbox"] {
  width: 20px;
}

.form-group .structure-select {
  width: 20vw;
  /* display: none; */
}

.form-group textarea {
  width: 80%;
  height: 150px;
}

.btn {
  margin: 0 10px;
}

