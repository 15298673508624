.navbar .btn {
  margin-top: 10px;
  margin-right: 10px;
  float: right;
}

.menu-content {
  display: flex;
}

.menu-content h1 {
  font-size: 2rem;
}

.select-world {
  flex: 2;
  padding: 20px;
  text-align: center;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.img-holder {
  max-width: 50vw;
  max-height: 50vh;
  margin: 10px 0;
  box-shadow: 2px 2px 5px #333;
  transition: transform .2s;
}

.img-holder:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.change-world {
  flex: 1;
  padding: 20px;
}

.create-world, .add-world {
  padding: 10px;
}

.select-world .btn {
  margin: 10px;
  color: transparent;
  width: 135px;
}

.select-world .selector {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.select-world .selector .world-name {
  margin: 10px;
}

.select-world .btn::-webkit-file-upload-button {
  visibility: hidden;
}

.select-world .btn::before {
  content: 'Upload Custom Image';
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.select-world .world-name {
  margin: 5px 0;
  font-size: 1.5rem;
}

.select-world .holder, .change-world .holder {
  background: #d9d9d9;
  box-shadow: 2px 2px 5px #333;
  padding: 10px;
}

.add-world input::-webkit-outer-spin-button,
.add-world input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
