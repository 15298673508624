.destination-navbar {
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}

.destination-overlay {
  position: absolute;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: inherit;
  background-color: rgb(217, 217, 217, 0.4);
}

.destination-navbar .destination-title {
  font-size: 1.5rem;
  display: flex;
  align-self: center;
}

.destination-navbar ._NGZSv {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-left: 10px;
}

.destination-navbar ._IYz6Z {
  display: flex;
}

.destination-navbar .btn {
  margin: 10px;
}

.destination {
  display: flex;
}

.destination-coordinates {
  flex: 1;
  background: #d9d9d9;
  margin: 20px;
  padding: 20px;
  height: 40vh;
  box-shadow: 2px 2px 5px #333;
  overflow: hidden;
  overflow-y: auto;
}

.destination-contains {
  flex: 2;
  background: #d9d9d9;
  margin: 20px;
  padding: 20px;
  height: 30vh;
  box-shadow: 2px 2px 5px #333;
  overflow: hidden;
  overflow-y: auto;
}

.destination-contains li {
  margin-left: 30px;
}

.destination-notes {
  flex: 1;
  background: #d9d9d9;
  margin: 20px;
  padding: 20px;
  height: 40vh;
  box-shadow: 2px 2px 5px #333;
}

.destination-notes .notes-area {
  cursor: pointer;
  height: 80% !important;
}

.destination-notes .notes-area:hover {
  background-color: #c4c4c4;
  transition: background-color 0.3s;
}

.destination-form input {
  padding: 10px;
  margin: 5px 0;
  border-style: none;
  border-radius: 3px;
  background-color: #eef5fc;
  width: 60%;
}

.destination-form input[type="number"] {
  width: 40%;
}

.destination-form button, .unstyled-button {
  all: unset;
  cursor: pointer;
}
